.Platreries {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    margin-top: 30px;
    width: 100%;
    height: 1150px;
    object-fit: cover;
}
/* .page-container {
    position: relative;
} */
.Platreriesmin {
    display: none;
}

.image-overlaypl {
    position: relative;
}

.image-overlaytextpl {
    position: absolute;
    top: 590px;
    left: 38%;
    font-size: 18px;
    font-weight: 700;
    transform: translate(-50%, -50%);
    text-align: left;
    color: rgb(255, 255, 255);
    z-index: 1;
    max-width: 100%;
    max-height: 1300px;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
        sans-serif;
}

.image-overlaytextpl main {
    max-width: 1400px;
}

.image-overlaytextpl h1,
.image-overlaytextpl p {
    margin-bottom: 20px;
    font-size: 23px;
    color: #ffffff;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
        sans-serif;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
/* Platreries.css */
.image-overlaytextpl h1 {
    color: #ffffff;
    font-size: 80px;
    font-weight: 700;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
        sans-serif;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.image-overlaytextpl li {
    font-size: 20px;

    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.image-overlaytextpl h2 {
    color: #ffffff;
    font-size: 30px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.Platreries2 {
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-left: 220px;
}
ul {
    width: 100%;
}
@media (max-width: 1239px) {
    .image-overlaytextpl h1 {
        font-size: 40px;
        text-shadow: none;
        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica,
            Arial, sans-serif;
        color: #000000; /* Set text color to black within media query */
    }
    .image-overlaytextpl h2 {
        text-shadow: none;
        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica,
            Arial, sans-serif;
        color: #000000;
        font-size: 30px;
    }
    .image-overlaytextpl li {
        font-size: 14px;
        margin-right: 60px;
        text-shadow: none;
        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica,
            Arial, sans-serif;
        color: #000000;
    }
    .Platreries2 {
        width: 20%;
        height: auto;
        object-fit: cover;
        margin-left: 50%;
    }
    .Platreriesmin {
        margin-top: 28px;
        display: block;
        width: 100%;
        max-height: 50vh;
    }
    .Platreries {
        display: none;
    }

    /* .image-overlaytextpl h1 {
        color: #c09875;
    } */
    /* .image-overlaytextpl ul li strong {
        color: #c09875;
    } */

    .image-overlaytextpl {
        /* ... (other styles) ... */
        font-size: 20px;
        position: static;
        transform: none;
        max-width: 100%;
        margin: 0 auto;
    }
    .page-containerpl {
        position: relative;
        width: 100%;
    }

    .image-overlaytextpl p {
        margin-left: -10px;
        text-shadow: none;
        margin-bottom: 20px;
        font-size: 18px;
        color: #000000;
        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica,
            Arial, sans-serif;
    }

    .image-overlaypl {
        text-align: center;
    }

    .image-overlaytextpl {
        max-height: none;
        font-size: 20px;
        position: absolute;
        top: 850px;
        left: 38%;

        /* background-color: #000000; */

        /* background-color: #add9da;
        background: linear-gradient(to bottom, #add9da, #88c9c5); */
        transform: translate(-50%, -50%);
        text-align: left;
        color: #000000;
        z-index: 1;
        max-width: 80%;
    }
    /* Add other styles as needed */
    /* body {
        min-width: 287px;
    } */

    .image-overlaytextpl {
        font-size: 20px;
        position: static;
        transform: none;
        max-width: 100%;
        margin: 0 auto;
        margin-right: 61px;
        margin-left: 61px;
        margin-top: 70px;
        padding-bottom: 40px;
    }
}
