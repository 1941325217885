.error h4 {
    text-align: center;
    font-size: 275px;
    color: #c09875;
    margin-top: 0px;
    margin-bottom: 0px;
}

.error h5 {
    text-align: center;
    font-size: 30px;
    color: #c09875;
    font-family: "Roboto", sans-serif;
}

.error h6 {
    text-align: center;
    font-size: 20px;
    text-decoration: underline;
    color: #c09875;
    font-family: "Roboto", sans-serif;
}

@media (max-width: 539px) {
    .error h4 {
        text-align: center;
        font-size: 138px;
        color: #c09875;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-top: 120px;
    }
    .error h5 {
        text-align: center;
        font-size: 17px;
        color: #c09875;
        font-family: "Roboto", sans-serif;

        margin-left: 80px;
        margin-right: 80px;
        margin-top: 1px;
        flex-wrap: wrap;
    }
    .error h6 {
        text-align: center;
        font-size: 19px;
        margin-top: 160px;
        text-decoration: underline;
        color: #c09875;
        font-family: "Roboto", sans-serif;
    }
}
