.Contactimg {
    margin-top: 28px;
    display: block;
    width: 100%;
    object-fit: cover;
    /* max-height: 50vh; */
    bottom: 0;
}

.image-overlayc h1 {
    color: #c09875;
}
.image-overlayc p {
    font-size: 30px;
    margin-bottom: 110px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.contact-form {
    position: absolute;
    top: 126%;
    left: 251px;
    transform: translate(-50%, -50%);
    z-index: 1; /* Ensure the form is above the image */
}

.overlaytextc {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* Add other styling as needed */
}

.form-input {
    position: relative; /* Change to relative positioning */
    display: block; /* Ensure each input is on a new line */
    margin-bottom: 10px; /* Adjust margin as needed */
}

.fixed-textarea {
    width: 496px;
    height: 77px;
    resize: none;
    /* Adjust other textarea styling as needed */
}

.form-submit {
    background-color: #007bff;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

/* Other styling for form elements */

.overlaytextc {
    position: absolute;
    top: 423px;
    left: 38%;
    font-size: 40px;
    font-weight: 700;
    transform: translate(-50%, -50%);
    text-align: left;
    color: rgb(255, 255, 255);
    z-index: 1;
    max-width: 80%;
    max-height: 1300px;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
        sans-serif;
}

@media (max-width: 1307px) {
    .fixed-textarea {
        width: 227px;

        height: 77px;
        resize: none;
        /* Adjust other textarea styling as needed */
    }
    .contact-form {
        position: static;
        margin-left: -62px;
        transform: none;
        margin-top: -40px;
        margin-bottom: 80px;
    }
    .overlaytextc ul {
        margin-left: -25px;
    }
    .overlaytextc h1 {
        margin-left: -40px;
        font-size: 40px;
    }
    .overlaytextc p {
        margin-left: -60px;
        padding-right: 40px;
    }
    .overlaytextc {
        padding-top: 46px;
        left: 38%;
        font-size: 40px;
        font-weight: 700;
        transform: none;
        text-align: left;
        color: rgb(255, 255, 255);
        z-index: 1;
        max-width: 80%;
        max-height: 1300px;
        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica,
            Arial, sans-serif;

        margin-left: 111px;
        position: static;
    }
    .Contactimg {
        margin-top: 28px;
        display: block;
        width: 100%;
        height: 320px;
        object-fit: cover;
        /* max-height: 50vh; */
        bottom: 0;
    }
}
