.footer {
    background-color: rgb(0, 0, 0);
    color: white;
    padding: 20 px;
    text-align: center;
    min-height: 120px;
    display: grid;
    place-items: center;
    margin-top: -5px;
    z-index: 2;
    position: relative;
    display: flex;
    justify-content: flex-end;
    justify-content: space-between; /* Ensures space between child elements */
    padding-right: 40px;
}
.footer a {
    color: white;
    text-decoration: none;
}

.contactt {
    margin-left: 20px;
    margin-top: 15px;
}

.mcrpro {
    /* margin-left: -20px; */
    margin-left: auto; /* Moves .mcrpro to the right */
    margin-top: -2px;
    white-space: nowrap;
}
.footer a:hover {
    color: white;
}
.footerLogo {
    position: absolute;
    left: 10px;
}
.footer img {
    margin-right: 1000px;
    margin-top: -28px;
    max-width: 50%;
    max-height: 50%;
    margin: 0 auto;
}
