.Decoration {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    margin-top: 30px;
}
.Decorationimg {
    margin-top: 28px;
    display: block;
    width: 100%;
    object-fit: cover;
    max-height: 50vh;
}
.Decorationimg4 {
    margin-top: 34px;
    display: block;
    width: 100%;
    object-fit: cover;
    max-height: 500px;
}
.overlaytextdec h1 {
    font-size: 80px;
    font-weight: 700;
    padding-top: 40px;
    padding-left: 40px;
    text-align: left;
    color: #c09875;

    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
        sans-serif;
}
.overlaytextdec h2 {
    /* color: #c09875; */
    color: #000000;

    font-size: 40px;
    font-weight: 700;

    text-align: left;
    color: rgb(0, 0, 0);

    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
        sans-serif;
}
.overlaytextdec ul li {
    color: #c09875;
    margin-left: 40px;
    font-size: 20px;
    font-weight: 700;

    text-align: left;

    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
        sans-serif;
}
.overlaytextdec p {
    color: #000000;
    color: #000000;
    padding-left: 40px;
    font-size: 20px;
    font-weight: 700;

    text-align: left;
    color: rgb(0, 0, 0);

    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
        sans-serif;
}
.overlaytextdec {
    margin-left: 61px;
    margin-top: -30px;
    margin-right: 61px;
    margin-bottom: 91px;
}

@media (max-width: 1245px) {
    .overlaytextdec ul li {
        margin-left: -10px;
        font-size: 14px;

        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica,
            Arial, sans-serif;
    }
    .overlaytextdec h1 {
        color: #000000;
        margin-top: 60px;
        padding-left: 20px;
        font-size: 40px;
        font-weight: 700;
        margin-right: 40px;
        text-align: left;
        color: rgb(0, 0, 0);

        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica,
            Arial, sans-serif;
    }
    .overlaytextdec p {
        color: #000000;

        padding-left: 0px;
        font-size: 20px;
        font-weight: 700;
        margin-right: 0px;
        text-align: left;
        color: rgb(0, 0, 0);

        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica,
            Arial, sans-serif;
    }
}
