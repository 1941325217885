.BackGround {
    max-width: 1506px;
    display: flex;
    /* background-color: rgb(238, 238, 238); */
    width: 85%;
    margin: 111px auto;
    padding: 20px;
    border-radius: 5px;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /* gap: 20px; */
    box-sizing: border-box;
}
section h2 {
    /* color: #bb8c49; */
    color: black;
    font-weight: bold;
    font-size: 35px;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
        sans-serif;
}

section p {
    font-size: 35px;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
        sans-serif;
    /* font-weight: bold; Adjust the font size as needed */
}
.flatTitle {
    /* position: absolute;
    color: white;
    padding-top: 20px;
    padding-left: 20px;
    font-size: medium;
    font-weight: bold; */
    position: absolute;
    color: white;
    padding-top: 20px;
    padding-left: 20px;
    font-size: larger;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.flat {
    border-radius: 5px;
    position: relative;
    height: 327px;
    width: 327px;
    box-sizing: border-box;
    margin: 20px;
    overflow: hidden;
}

.flat img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 539px) {
    section h2 {
        /* color: #bb8c49; */
        color: black;
        font-weight: bold;
        font-size: 22px;
        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica,
            Arial, sans-serif;
    }
    section p {
        font-size: 19px;
        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica,
            Arial, sans-serif;
        /* font-weight: bold; Adjust the font size as needed */
    }
    .BackGround {
        background-color: white;
        width: 100%;
        margin: 40px auto;
        padding: 10px;
        row-gap: 10px;
        column-gap: 10px;
        justify-content: center;
        gap: 10px;
    }

    .flat {
        margin: 10px;
    }
}

@media (max-width: 539px) {
    .BackGround {
        margin: 20px auto;
        padding: 10px;
        row-gap: 10px;
        column-gap: 10px;
        gap: 10px;
        margin-top: -50px;
        padding-left: 30px;
        padding-right: 30px;
        /* margin-right: 20px; */
    }

    .flat {
        width: 100%;
    }

    .flat img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin: 0 auto;
    }
}
.newFicheContainer {
    border-radius: 10px;
}
/* .BackGround {
    display: flex;
   
    width: 85%;
    margin: 111px auto;
    padding: 20px;
    border-radius: 5px;
    flex-wrap: wrap;
    justify-content: space-evenly;
  
    box-sizing: border-box;
}
section h2 {
   
    color: black;
    font-weight: bold;
    font-size: 35px;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
        sans-serif;
}

section p {
    font-size: 35px;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
        sans-serif;
 
}
.flatTitle {
    
    position: absolute;
    color: white;
    padding-top: 20px;
    padding-left: 20px;
    font-size: larger;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.flat {
    border-radius: 5px;
    position: relative;
    height: 327px;
    width: 327px;
    box-sizing: border-box;
    margin: 20px;
    overflow: hidden;
}

.flat img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 539px) {
    .BackGround {
        background-color: white;
        width: 100%;
        margin: 40px auto;
        padding: 10px;
        row-gap: 10px;
        column-gap: 10px;
        justify-content: center;
        gap: 10px;
    }

    .flat {
        margin: 10px;
    }
}

@media (max-width: 539px) {
    .BackGround {
        margin: 20px auto;
        padding: 10px;
        row-gap: 10px;
        column-gap: 10px;
        gap: 10px;
        margin-top: -50px;
    }

    .flat {
        width: 100%;
    }

    .flat img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin: 0 auto;
    }
}
.newFicheContainer {
    border-radius: 10px;
} */
