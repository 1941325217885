.Contactimgg {
    margin-top: 28px;
    display: block;
    width: 100%;
    object-fit: cover;
    max-height: 50vh;
}
.overlaytextcc {
    font-size: 15px;
    position: static;
    transform: none;
    max-width: 100%;
    margin: 0 auto;
    margin-right: 61px;
    margin-left: 61px;
    margin-top: 70px;
    padding-bottom: 40px;
}

.overlaytextcc h1 {
    font-size: 80px;
    color: #c09875;
}
.overlaytextcc h2 {
    font-size: 40px;
}
.overlaytextcc li {
    font-size: 20px;
    font-weight: 700;

    color: #c09875;
}
.overlaytextcc p {
    font-size: 20px;
    font-weight: 700;
}
.overlaytextcc {
    margin-top: 100px;
    margin-left: 100px;
    margin-bottom: 50px;
}
@media (max-width: 981px) {
    .overlaytextcc h1 {
        font-size: 40px;
        margin-left: -40px;
        font-weight: 700;
    }
    .overlaytextcc h3 {
        font-size: 20px;
        margin-left: -50px;
        font-weight: 700;
    }
    .overlaytextcc p {
        font-size: 20px;
        margin-left: -50px;
        font-weight: 700;
    }
    .overlaytextcc li {
        margin-left: -50px;
        font-size: 14px;
        font-weight: 700;
        margin-right: 30px;
        color: #c09875;
    }
}
