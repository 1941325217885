.page-container {
    position: relative;
    width: 100%;
}
.peinture-container {
    position: relative;
    width: 100%;
    overflow: hidden;
}
.peintureimg3 {
    display: none;
}
.peintureimg {
    width: 100%;
    padding-top: 28px;
    height: auto;
}
.peintureimgh {
    padding-left: 40px;
    width: 90%;
    padding-top: 35px;
    height: auto;
}
.image-overlayb p {
    font-size: 25px;
}

.image-overlayb {
    text-align: center;
}

.image-overlaytextb {
    position: absolute;
    top: 680px;
    left: 38%;
    font-size: 40px;
    font-weight: 700;
    transform: translate(-50%, -50%);
    text-align: left;
    color: rgb(0, 0, 0);
    z-index: 1;
    max-width: 80%;
    max-height: 1300px;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
        sans-serif;
}

.peintureimg2 {
    margin-top: 40px;
    margin-bottom: 25px;
    margin-left: 80px;
    width: 80%;
}
.peintureimg {
    display: block;
}

@media (max-width: 1337px) {
    .image-overlaytextb h1 {
        margin-left: -10px;
        color: #c09875;
        font-size: 54px;
    }

    .image-overlaytextb li {
        margin-right: 40px;
        font-size: 14px;
        color: #000000;
    }
    .image-overlaytextb strong {
        color: #c09875;
    }

    .peintureimg {
        display: block;
        width: 100%;
        object-fit: cover;
        max-height: 50vh;
    }
    .peintureimg3 {
        margin-top: 34px;
        display: block;
        width: 100%;
        max-height: 50vh;
        object-fit: cover;
        object-position: center bottom;
    }
    .image-overlaytextb {
        font-size: 15px;
        position: static;
        transform: none;
        max-width: 100%;
        margin: 0 auto;
    }
    .page-container {
        position: relative;
        width: 100%;
    }
    .peinture-container {
        position: relative;
        width: 100%;
        overflow: hidden;
    }

    .image-overlayb p {
        margin-left: -12px;

        font-size: 20px;
    }

    .image-overlayb {
        text-align: center;
    }

    .image-overlaytextb {
        max-height: none;
        font-size: 15px;
        position: absolute;
        top: 850px;
        left: 38%;

        transform: translate(-50%, -50%);
        text-align: left;
        color: #000000;
        z-index: 1;
        max-width: 80%;
    }
    .peintureimgh {
        padding-left: 0px;
        width: 90%;
        padding-top: 35px;
        height: auto;
    }
    .peintureimg2 {
        margin-left: 0%;
        width: 80%;
    }
    .image-overlaytextb {
        font-size: 15px;
        position: static;
        transform: none;
        max-width: 100%;
        margin: 0 auto;
        margin-right: 61px;
        margin-left: 61px;
        margin-top: 70px;
        padding-bottom: 40px;
    }
}
/* .page-container {
    position: relative;
    width: 100%;
}
.peinture-container {
    position: relative;
    width: 100%;
    overflow: hidden;
}
.peintureimg3 {
    display: none;
}
.peintureimg {
    width: 100%;
    padding-top: 28px;
    height: auto;
}
.peintureimgh {
    padding-left: 40px;
    width: 90%;
    padding-top: 35px;
    height: auto;
}
.image-overlayb p {
    font-size: 25px;
}

.image-overlayb {
    text-align: center;
}

.image-overlaytextb {
    position: absolute;
    top: 680px;
    left: 38%;
    font-size: 40px;
    font-weight: 700;
    transform: translate(-50%, -50%);
    text-align: left;
    color: rgb(0, 0, 0);
    z-index: 1;
    max-width: 80%;
    max-height: 1300px;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
        sans-serif;
}

.peintureimg2 {
    margin-top: 40px;
    margin-bottom: 25px;
    margin-left: 80px;
    width: 80%;
}
.peintureimg {
    display: block;
}

@media (max-width: 1337px) {
    .image-overlaytextb h1 {
        color: #c09875;
        font-size: 70px;
    }

    .image-overlaytextb li {
        color: #000000;
        margin-right: 100px;
    }
    .image-overlaytextb strong {
        color: #c09875;
    }

    .peintureimg {
        display: block;
        width: 100%;
        object-fit: cover;
        max-height: 50vh;
    }
    .peintureimg3 {
        margin-top: 34px;
        display: block;
        width: 100%;
        max-height: 50vh;
        object-fit: cover;
        object-position: center bottom;
    }
    .image-overlaytextb {
        font-size: 15px;
        position: static;
        transform: none;
        max-width: 100%;
        margin: 0 auto;
    }
    .page-container {
        position: relative;
        width: 100%;
    }
    .peinture-container {
        position: relative;
        width: 100%;
        overflow: hidden;
    }

    .image-overlayb p {
        font-size: 20px;
    }

    .image-overlayb {
        text-align: center;
    }

    .image-overlaytextb {
        max-height: none;
        font-size: 15px;
        position: absolute;
        top: 850px;
        left: 38%;

        transform: translate(-50%, -50%);
        text-align: left;
        color: #000000;
        z-index: 1;
        max-width: 80%;
    }
    .peintureimgh {
        padding-left: 0px;
        width: 90%;
        padding-top: 35px;
        height: auto;
    }
    .peintureimg2 {
        margin-left: 0%;
        width: 80%;
    }
    .image-overlaytextb {
        font-size: 15px;
        position: static;
        transform: none;
        max-width: 100%;
        margin: 0 auto;
        margin-right: 61px;
        margin-left: 61px;
        margin-top: 70px;
        padding-bottom: 40px;
    }
} */
