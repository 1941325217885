.image-overlayr {
    width: 100%;
    height: 100%;
}
.revdesol {
    margin-top: 40px;
    margin-bottom: 20px;
    width: 80%;
}
.background-imager {
    display: block;
    width: 100%;
    min-height: 1100px;
    padding-top: 28px;
    height: auto;
}
.overlay-textr3r h1 {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    color: #c09875;
}
.overlay-textr3r h2 {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    color: #c09875;
}
.overlay-textr3r li {
    /* font-size: 18px; */

    font-size: 22px;
    color: #ffffff;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
        sans-serif;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.overlay-textr3r p {
    margin-bottom: 20px;
    /* font-size: 18px; */
    font-size: 24px;
    color: #ffffff;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
        sans-serif;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.overlay-textr3r {
    position: absolute;
    top: 700px;
    left: 38%;
    font-size: 40px;
    font-weight: 700;
    transform: translate(-50%, -50%);
    text-align: left;
    color: rgb(0, 0, 0);
    z-index: 1;
    max-width: 80%;
    max-height: 1300px;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
        sans-serif;
}

@media (max-width: 1245px) {
    .background-imager {
        display: block;
        width: 100%;
        object-fit: cover;
        max-height: 50vh; /* Set maximum height for smaller screens */
        min-height: auto;
    }
    .overlay-textr3r {
        font-size: 40px;
        font-weight: 700;
        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica,
            Arial, sans-serif;
        font-size: 15px;
        position: static;
        transform: none;
        max-width: 100%;

        margin: 0 auto;
        margin-right: 61px;
        margin-left: 61px;
        margin-top: 70px;
        padding-bottom: 50px;
    }

    .overlay-textr3r h1 {
        text-shadow: none;
        color: #c09875;
        font-size: 40px;
        font-weight: 700;
        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica,
            Arial, sans-serif;
    }
    .overlay-textr3r h2 {
        text-shadow: none;
        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica,
            Arial, sans-serif;
        color: #c09875;
    }
    .overlay-textr3r li {
        padding-right: 42px;
        /* margin-right: 60px; */
        margin-left: -17px;
        text-shadow: none;
        font-size: 14px;
        color: #000000;
        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica,
            Arial, sans-serif;
    }
    .overlay-textr3r p {
        margin-left: -15px;
        text-shadow: none;
        margin-bottom: 20px;
        font-size: 18px;
        color: #000000;
        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica,
            Arial, sans-serif;
    }
}
