.image-overlay p {
    font-size: 20px;
}
.Isolationimgiso {
    width: 100%;
    height: 1100px;
}

.image-overlay {
    text-align: center;
}
.image-overlayiso {
    margin-top: 28px;
}
.Isolationimgiso2 {
    display: none;
}
.image-overlaytextiso {
    position: absolute;
    top: 537px;
    left: 47%;

    transform: translate(-50%, -50%);
    text-align: left;
    color: white;
    z-index: 1;
    max-width: 80%;
    max-height: 650px;
}
.image-overlaytextiso h1 {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    color: #ffffff;
    font-size: 80px;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
        sans-serif;
    white-space: nowrap;
}
.image-overlaytextiso p {
    color: #ffffff;
    font-size: 25px;
    font-weight: 500;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
        sans-serif;
}
.image-overlaytextiso h2 {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    color: #ffffff;
    font-size: 25px;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
        sans-serif;
}
.image-overlaytextiso ul li {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    color: #ffffff;
    font-size: 23px;
    font-weight: 500;

    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
        sans-serif;
}

@media (max-width: 1276px) {
    .Isolationimgiso2 {
        margin-top: 28px;
        display: block;
        width: 100%;
        max-height: 50vh;
        object-fit: cover;
    }
    .image-overlaytextiso p {
        text-shadow: none;
        color: #000000;
    }
    .image-overlaytextiso h1 {
        text-shadow: none;
        font-size: 40px;
        color: #000000;
        white-space: normal;
    }
    .image-overlaytextiso h2 {
        text-shadow: none;
        color: #000000;
    }
    .image-overlaytextiso ul li {
        margin-left: -20px;
        font-size: 14px;
        text-shadow: none;
        color: #000000;
        margin-right: 60px;
    }
    .Isolationimgiso {
        display: none;
    }

    /* .peintureimg {
        display: none;
    } */
    /* .peintureimg3 {
        margin-top: 34px;
        display: block;
        width: 100%;
        max-height: 50vh;
    } */
    .image-overlaytextiso {
        font-size: 20px;
        position: static;
        transform: none;
        max-width: 100%;
        margin: 0 auto;
    }
    .page-containeriso {
        position: relative;
        width: 100%;
    }

    .image-overlayiso p {
        margin-left: -10px;
        font-size: 20px;
    }

    .image-overlayiso {
        text-align: center;
    }

    .image-overlaytextiso {
        max-height: none;
        font-size: 20px;
        position: absolute;
        top: 850px;
        left: 38%;

        /* background-color: #000000; */

        /* background-color: #add9da;
        background: linear-gradient(to bottom, #add9da, #88c9c5); */
        transform: translate(-50%, -50%);
        text-align: left;
        color: #000000;
        z-index: 1;
        max-width: 80%;
    }

    /* body {
        min-width: 287px;
    } */

    .image-overlaytextiso {
        font-size: 20px;
        position: static;
        transform: none;
        max-width: 100%;
        margin: 0 auto;
        margin-right: 61px;
        margin-left: 61px;
        margin-top: 70px;
        padding-bottom: 40px;
    }
}
